<template>
  <div class="table">
    <div class="head">
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="clickadd()"
      >
        添加新闻
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="
          tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        style="width: 100%"
        border
      >
        <el-table-column prop="newsTitle" label="新闻标题"> </el-table-column>
        <el-table-column prop="newsLabel" label="新闻标签"> </el-table-column>
        <el-table-column label="操作" width="290px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              编辑
            </el-button>
            <el-button type="success" size="mini" @click="handletop(scope.row)">
              上架
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="handleDelete(scope.row)"
            >
              下架
            </el-button>
            <el-button type="danger" size="mini" @click="deleteid(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="10"
          layout="sizes, prev, pager, next"
          :total="tableData.length"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="新闻标题">
          <el-input v-model="formLabelAlign.newsTitle"></el-input>
        </el-form-item>
        <el-form-item label="新闻标签">
          <el-select
            v-model="value"
            filterable
            placeholder="请选择"
            @change="addtag(value)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.dictValue"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="已选标签">
          <el-tag
            v-for="(item, index) in valuelist"
            :key="index"
            closable
            @close="tagdel(index)"
            style="margin-right: 10px"
          >
            {{ item }}
          </el-tag>
        </el-form-item>
        <el-form-item label="新闻图片">
          <!-- <el-input v-model="formLabelAlign.newsImages"></el-input> -->
          <el-upload
            class="avatar-uploader"
            action="https://www.hyzfjd.cn/api/sys/file/upload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :headers="headers"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="新闻内容">
          <vue-tinymce
            v-model="formLabelAlign.newsContent"
            :setting="setting"
          />
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新建弹窗 -->
    <el-dialog
      title="新建"
      :visible.sync="dialogVisible2"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="新闻标题">
          <el-input v-model="formLabelAlign.newsTitle"></el-input>
        </el-form-item>
        <el-form-item label="新闻标签">
          <el-select
            v-model="value"
            filterable
            placeholder="请选择"
            @change="addtag(value)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.dictValue"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="已选标签">
          <el-tag
            v-for="(item, index) in valuelist"
            :key="index"
            closable
            @close="tagdel(index)"
            style="margin-right: 10px"
          >
            {{ item }}
          </el-tag>
        </el-form-item>
        <el-form-item label="新闻图片">
          <!-- <el-input v-model="formLabelAlign.newsImages"></el-input> -->
          <el-upload
            class="avatar-uploader"
            action="https://www.hyzfjd.cn/api/sys/file/upload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :headers="headers"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="新闻内容">
          <vue-tinymce
            v-model="formLabelAlign.newsContent"
            :setting="setting"
          />
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit2()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetsysDictTypelistData,
  GetsysNewsManager,
  PostsysNewsManagersave,
  PutsysNewsManagerupdate,
  PutsysNewsManagernewsUp,
  PutsysNewsManagernewsDown,
  DeletesysNewsManagerdeleteById,
} from "@/request/api";
var token = localStorage.getItem("token");
export default {
  name: "Htgltableoption",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //弹出框判定
      dialogVisible2: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        newsTitle: "",
        newsLabel: "",
        newsImages: "",
        newsContent: "",
      },
      checked: false,
      content: "", //富文本绑定值，点击提交按钮可以得到富文本插件的值
      setting: {
        menubar: false, //是否显示菜单栏toolbar_drawer: "sliding",
        // quickbars_selection_toolbar:
        //   "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        // plugins: "link image media table lists fullscreen quickbars",
        language: "zh_CN", //富文本语言
        height: 500, //富文本的高度
        branding: false, //隐藏右下角官方超链接
        inline: false,
      },
      options: [], //类型列表
      value: "", //选中值
      valuelist: [], //已选标签
      id: "",
      imageUrl: "",
      headers: { Authorization: token },
      img: "",
      currentPage: 1, //分页
      pageSize: 10,
    };
  },

  methods: {
    // 上传图片
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      // console.log(file,123456);
      if (res.code == 200) {
        this.img = res.data;
      }
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
    // 编辑
    handleUpdate(v) {
      // console.log(v);
      this.id = v.id;
      this.formLabelAlign.newsTitle = v.newsTitle;
      this.imageUrl = v.newsImages;
      this.formLabelAlign.newsContent = v.newsContent;
      this.formLabelAlign.newsLabel = v.newsLabel;
      this.valuelist = this.formLabelAlign.newsLabel.split(",");
      this.dialogVisible = true;
    },
    // 编辑确定
    handleSubmit() {
      PutsysNewsManagerupdate({
        createTime: "",
        deleteFlag: "",
        id: this.id,
        newsContent: this.formLabelAlign.newsContent,
        newsImages: this.img,
        newsLabel: this.formLabelAlign.newsLabel,
        newsTitle: this.formLabelAlign.newsTitle,
        newsUp: "",
        updateTime: "",
      }).then((res) => {
        if (res.code == 200) {
          this.imageUrl = "";
          this.seelist();
          this.dialogVisible = false;
        }
      });
    },
    // 上架
    handletop(v) {
      PutsysNewsManagernewsUp({ id: v.id }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "上架成功!",
          });
        }
      });
    },

    // 下架
    handleDelete(v) {
      PutsysNewsManagernewsDown({ id: v.id }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "下架成功!",
          });
        }
      });
    },

    // 新增标签
    addtag(v) {
      // console.log(v);
      this.options.map((item, index) => {
        if (v == item.id) {
          this.valuelist.push(item.dictLabel);
          this.formLabelAlign.newsLabel = this.valuelist.join(",");
        }
      });
    },
    // 删除已选标签
    tagdel(v) {
      this.valuelist.splice(v, 1);
      this.formLabelAlign.newsLabel = this.valuelist.join(",");
    },
    //添加新闻
    clickadd() {
      this.valuelist = [];
      this.value = "";
      this.imageUrl = "";
      this.formLabelAlign.newsTitle = "";
      this.formLabelAlign.newsLabel = "";
      this.formLabelAlign.newsImages = "";
      this.formLabelAlign.newsContent = "";
      this.dialogVisible2 = true;
    },
    // 添加新闻确定
    handleSubmit2() {
      // console.log(this.imageUrl);
      PostsysNewsManagersave({
        createTime: "",
        deleteFlag: "",
        id: "",
        newsTitle: this.formLabelAlign.newsTitle,
        newsImages: this.img,
        newsLabel: this.formLabelAlign.newsLabel,
        newsContent: this.formLabelAlign.newsContent,
        newsUp: "",
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.dialogVisible2 = false;
          this.seelist();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 删除新闻
    deleteid(v) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeletesysNewsManagerdeleteById({ id: v.id }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 标签类型列表
    seelist2() {
      GetsysDictTypelistData({
        dictSort: 0,
        dictType: "标签",
        pageNum: 1,
        pageSize: 100000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.options = res.data.list;
        }
      });
    },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.currentPage = val;
    },
    // 新闻列表
    seelist() {
      GetsysNewsManager({
        newsTitle: "",
        pageNum: 1,
        pageSize: 1000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
        }
      });
    },
  },
  mounted() {
    this.seelist();
    this.seelist2();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .Yes {
    color: green;
  }
  .No {
    color: red;
  }
}
.vue_richtext {
  background-color: #fff;
  .submit_btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    color: #fff;
    background-color: #eb5d20;
    text-align: center;
    margin: 20px auto 0;
    cursor: pointer;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.block {
  text-align: center;
  margin-top: 15px;
}
</style>